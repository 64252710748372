import Axios from 'axios'
import cn from 'classnames'
import { createBrowserHistory } from 'history'
import { observer, Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Component, lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import AppStore from 'stores/AppStore'

const Navbar = lazy(
  () => import(/* webpackChunkName: "Navbar" */ './components/Navbar'),
)
const Notification = lazy(
  () =>
    import(/* webpackChunkName: "Notification" */ './components/Notification'),
)
const Locked = lazy(
  () => import(/* webpackChunkName: "Locked" */ './components/Locked'),
)
const Footer = lazy(
  () => import(/* webpackChunkName: "Footer" */ `./components/Footer.js`),
)

const Schedule = lazy(
  () =>
    import(/* webpackChunkName: "Schedule" */ `./pages/Schedule/Schedule.js`),
)
const Cache = lazy(
  () => import(/* webpackChunkName: "Cache" */ `./pages/Cache.js`),
)
const CacheJobs = lazy(
  () => import(/* webpackChunkName: "Cache" */ `./pages/CacheJobs.js`),
)
const AMQ = lazy(() => import(/* webpackChunkName: "AMQ" */ `./pages/AMQ.js`))
const AMQHost = lazy(
  () => import(/* webpackChunkName: "AMQHost" */ `./pages/AMQHost.js`),
)
const NonGameEvent = lazy(
  () =>
    import(
      /* webpackChunkName: "NonGameEvent" */ `./pages/NonGameEvent/NonGameEvent.js`
    ),
)
const ABS = lazy(() => import(/* webpackChunkName: "ABS" */ `./pages/ABS/ABS`))
const Boss = lazy(
  () => import(/* webpackChunkName: "Boss" */ `./pages/Boss/Boss`),
)
const GameAdmin = lazy(
  () => import(/* webpackChunkName: "Boss" */ `./pages/GameAdmin/GameAdmin`),
)
const Pregame = lazy(
  () => import(/* webpackChunkName: "Pregame" */ `./pages/Pregame.js`),
)
const Uniforms = lazy(
  () => import(/* webpackChunkName: "Uniforms" */ `./pages/Uniforms.js`),
)
const Events = lazy(
  () => import(/* webpackChunkName: "Events" */ `./pages/Events/Events.js`),
)
const Tracking = lazy(
  () =>
    import(/* webpackChunkName: "Tracking" */ `./pages/Tracking/Tracking.js`),
)
const Scoresheet = lazy(
  () => import(/* webpackChunkName: "Scoresheet" */ `./pages/Scoresheet.js`),
)
const Postgame = lazy(
  () => import(/* webpackChunkName: "Postgame" */ `./pages/Postgame.js`),
)
const Play = lazy(
  () => import(/* webpackChunkName: "Play" */ `./pages/Play.js`),
)
const Login = lazy(
  () => import(/* webpackChunkName: "Login" */ `./pages/Login.js`),
)
const LoginBreakglass = lazy(
  () =>
    import(
      /* webpackChunkName: "LoginBreakglass" */ `./pages/LoginBreakglass.js`
    ),
)
const About = lazy(
  () => import(/* webpackChunkName: "About" */ `./pages/About.js`),
)
const NotFound = lazy(
  () => import(/* webpackChunkName: "NotFound" */ `./pages/NotFound.js`),
)
const Reporting = lazy(() => import('./pages/Reporting'))

const browserHistory = createBrowserHistory()
const router = new RouterStore()

const history = syncHistoryWithStore(browserHistory, router)

const store = new AppStore(router)

store.initialize()

function query(path, params) {
  const source = Axios.CancelToken.source()
  const promise = Axios.get(path, { params, cancelToken: source.token }).then(
    ({ data }) => data,
  )
  promise.cancel = () => {
    source.cancel(`Query was cancelled by React Query`)
  }
  return promise
}

function defaultQueryFunction({ queryKey }) {
  if (typeof queryKey === 'string') {
    return query(queryKey)
  }
  if (Array.isArray(queryKey)) {
    const [path, params] = queryKey
    return query(path, params)
  }
  throw new Error(
    'Unexpected queryKey format - must supply string or [path, queryParams]',
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

@observer
class App extends Component {
  // eslint-disable-next-line react/no-unsafe
  UNSAFE_componentWillMount() {
    if (!store.auth.isAuthenticated) {
      store.auth.getSession()
    }

    store.config.update()
  }

  componentDidMount() {
    this.initDware()
    store.getEnv()

    store.ui.resize()
    window.addEventListener('resize', store.ui.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', store.ui.resize)
  }

  initDware() {
    setTimeout(() => {
      if (window.Diamondware) {
        window.Diamondware.initGame(9)
      }
    })
  }

  render() {
    return (
      <Router history={history}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <div className={cn('app', {})}>
              <Toaster
                position='bottom-right'
                gutter={8}
                containerClassName=''
                containerStyle={{}}
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  // style: {
                  //   background: '#363636',
                  //   color: '#fff',
                  // },
                  // Default options for specific types
                  success: {
                    duration: 3000,
                    theme: {
                      // primary: 'green',
                      // secondary: 'black',
                    },
                  },
                }}
              />
              <Suspense fallback={<Loading />}>
                <Route path='*'>
                  <Navbar />
                </Route>
              </Suspense>
              <Suspense fallback={<Loading />}>
                <Route path='*'>
                  <Notification />
                </Route>
              </Suspense>
              <Suspense fallback={<Loading />}>
                <Route path='*'>
                  <Locked />
                </Route>
              </Suspense>

              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    exact
                    path={`/(${Object.keys(store.schedule.tabs).join('|')})?`}
                  >
                    <Schedule />
                  </Route>

                  <Route exact path='/cache/jobs'>
                    <CacheJobs />
                  </Route>

                  <Route exact path='/cache'>
                    <Cache />
                  </Route>

                  <Route exact path='/reporting'>
                    <Reporting />
                  </Route>

                  <Route exact path='/amq'>
                    <AMQ />
                  </Route>
                  <Route exact path='/amq/:host'>
                    <AMQHost />
                  </Route>

                  <Route exact path='/nonGameEvents/:eventId'>
                    <NonGameEvent />
                  </Route>

                  <Route exact path='/leagues/:sportCode'>
                    <Schedule />
                  </Route>

                  <Route exact path='/games/:gamePk/abs/:absTab'>
                    <ABS />
                  </Route>

                  <Route exact path='/games/:gamePk/boss/:bossTab'>
                    <Boss />
                  </Route>

                  <Route exact path='/games/:gamePk/admin'>
                    <GameAdmin />
                  </Route>

                  <Route exact path='/games/:gamePk/pregame'>
                    <Pregame />
                  </Route>

                  <Route exact path='/games/:gamePk/uniforms'>
                    <Uniforms />
                  </Route>

                  <Route exact path='/games/:gamePk/plays'>
                    <Events />
                  </Route>

                  <Route exact path='/games/:gamePk/tracking'>
                    <Tracking />
                  </Route>
                  <Route exact path='/games/:gamePk/postgame'>
                    <Postgame />
                  </Route>

                  <Route exact path='/games/:gamePk/scoresheet'>
                    <Scoresheet />
                  </Route>

                  <Route exact path='/games/:gamePk/plays/:playId'>
                    <Play />
                  </Route>

                  <Route exact path='/nonGameEvents/:gamePk/plays/:playId'>
                    <Play />
                  </Route>

                  <Route exact path='/login'>
                    <Login />
                  </Route>

                  <Route exact path='/login/authError'>
                    <Login authError='true' />
                  </Route>

                  <Route exact path='/login/breakglass'>
                    <LoginBreakglass />
                  </Route>

                  <Route exact path='/about'>
                    <About />
                  </Route>
                  <Route
                    exact
                    path='/games/:gamePk'
                    render={(props) => (
                      <Redirect
                        to={`/games/${props.match.params.gamePk}/${store.defaultGamePage}`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path='/games/:gamePk/boss'
                    render={(props) => (
                      <Redirect
                        to={`/games/${props.match.params.gamePk}/boss/stringer`}
                      />
                    )}
                  />

                  <Route path='*'>
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path='/cache' exact component={Noop} />
                  <Route path='/games/*' exact>
                    <Footer />
                  </Route>
                </Switch>
              </Suspense>
            </div>
          </QueryClientProvider>
        </Provider>
      </Router>
    )
  }
}

function Noop() {
  return null
}

const Loading = () => (
  <div id='loader' className='has-text-dark'>
    <span id='spinner' className='icon has-text-grey'>
      <i className='fas fa-2x fa-spinner fa-pulse' />
    </span>
  </div>
)

export default App
