export const NotificationTypes = {
  UPDATES_PENDING: 'UPDATES_PENDING',
  UPDATES_SENT: 'UPDATES_SENT',
  FINALIZATION_SENT: 'FINALIZATION_SENT',
  UPDATES_ERROR: 'UPDATES_ERROR',
  UPDATES_ACCEPTED: 'UPDATES_ACCEPTED',

  DIRECT_UPDATE_SUCCESS: 'DIRECT_UPDATE_SUCCESS',
  DIRECT_UPDATE_ERROR: 'DIRECT_UPDATE_ERROR',

  PLAY_STRING_ERROR: 'PLAY_STRING_ERROR',

  AUDIT_SUCCESS: 'AUDIT_SUCCESS',
  AUDIT_ERROR: 'AUDIT_ERROR',

  PITCH_META_SUCCESS: 'PITCH_META_SUCCESS',
  PITCH_META_ERROR: 'PITCH_META_ERROR',

  SCRUB_SUCCESS: 'SCRUB_SUCCESS',
  SCRUB_ERROR: 'SCRUB_ERROR',

  RESOLVE_SUCCESS: 'RESOLVE_SUCCESS',
  RESOLVE_ERROR: 'RESOLVE_ERROR',

  REMOVE_BOSS_SUCCESS: 'REMOVE_BOSS_SUCCESS',
  REMOVE_BOSS_ERROR: 'REMOVE_BOSS_ERROR',

  REMOVE_STRINGER_SUCCESS: 'REMOVE_STRINGER_SUCCESS',
  REMOVE_STRINGER_ERROR: 'REMOVE_STRINGER_ERROR',

  ADD_BAD_PLAY_DATA_SUCCESS: 'ADD_BAD_PLAY_DATA_SUCCESS',
  ADD_BAD_PLAY_DATA_ERROR: 'ADD_BAD_PLAY_DATA_ERROR',

  DELETE_BAD_PLAY_DATA_SUCCESS: 'DELETE_BAD_PLAY_DATA_SUCCESS',
  DELETE_BAD_PLAY_DATA_ERROR: 'DELETE_BAD_PLAY_DATA_ERROR',

  GENERIC_SUCCESS: 'GENERIC_SUCCESS',
  GENERIC_ERROR: 'GENERIC_ERROR',
  GENERIC_INFO: 'GENERIC_INFO',

  ROSTERS_REFRESHED: 'ROSTERS_REFRESHED',
  ROSTERS_REFRESH_FAILED: 'ROSTERS_REFRESH_FAILED',
}

export const UpdateActions = {
  UPDATE: 'update',
}

export const UpdateTypes = {}

// naming convention
export const EditTypes = {
  MODIFY_EVENT: 'MODIFY_EVENT',
  ADD_EVENT: 'ADD_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',

  ADD_PITCH: 'ADD_PITCH',
  ADD_AT_BAT_START: 'ADD_AT_BAT_START',
  EDIT_PITCH: 'EDIT_PITCH',

  EDIT_REVIEWS: 'EDIT_REVIEWS',
  EDIT_VIOLATIONS: 'EDIT_VIOLATIONS',
  MODIFY_DEFENSIVE_SHIFT_VIOLATION: 'MODIFY_DEFENSIVE_SHIFT_VIOLATION',
  EDIT_PLAY: 'EDIT_PLAY',
  EDIT_PLAY_TABLE: 'EDIT_PLAY_TABLE',
  EDIT_RESULT: 'EDIT_RESULT',
  MODIFY_PLAY: 'MODIFY_PLAY',

  EDIT_FLAGS: 'EDIT_FLAGS',
  EDIT_TRAJECTORY: 'EDIT_TRAJECTORY',
  EDIT_LOCATION: 'EDIT_LOCATION',
  EDIT_FIELDERS: 'EDIT_FIELDERS',
  EDIT_RUNNERS: 'EDIT_RUNNERS',
  EDIT_RUNNER_MOVEMENT: 'EDIT_RUNNER_MOVEMENT',

  MODIFY_SUB: 'MODIFY_SUB',
  QUICK_SUB: 'QUICK_SUB',
  ADD_SUB: 'ADD_SUB',
  EDIT_SUB: 'EDIT_SUB',
  EDIT_STARTERS: 'EDIT_STARTERS',
  EDIT_UMPIRES: 'EDIT_UMPIRES',
  DELETE_SUB: 'DELETE_SUB',

  EDIT_SCOREKEEPERS: 'EDIT_SCOREKEEPERS',
  EDIT_WEATHER: 'EDIT_WEATHER',

  MODIFY_BADJ: 'MODIFY_BADJ',
  ADD_BADJ: 'ADD_BADJ',
  EDIT_BADJ: 'EDIT_BADJ',
  DELETE_BADJ: 'DELETE_BADJ',

  MODIFY_PADJ: 'MODIFY_PADJ',
  ADD_PADJ: 'ADD_PADJ',
  EDIT_PADJ: 'EDIT_PADJ',
  DELETE_PADJ: 'DELETE_PADJ',

  MODIFY_EJECT: 'MODIFY_EJECT',
  ADD_EJECT: 'ADD_EJECT',
  EDIT_EJECT: 'EDIT_EJECT',
  DELETE_EJECT: 'DELETE_EJECT',

  MODIFY_INJURY: 'MODIFY_INJURY',
  ADD_INJURY: 'ADD_INJURY',
  EDIT_INJURY: 'EDIT_INJURY',
  DELETE_INJURY: 'DELETE_INJURY',

  MODIFY_RUNNER: 'MODIFY_RUNNER',
  ADD_RUNNER: 'ADD_RUNNER',
  EDIT_RUNNER: 'EDIT_RUNNER',
  DELETE_RUNNER: 'DELETE_RUNNER',

  MODIFY_COM: 'MODIFY_COM',
  ADD_COM: 'ADD_COM',
  EDIT_COM: 'EDIT_COM',
  DELETE_COM: 'DELETE_COM',

  MODIFY_UMPSUB: 'MODIFY_UMPSUB',
  ADD_UMPSUB: 'ADD_UMPSUB',
  EDIT_UMPSUB: 'EDIT_UMPSUB',
  DELETE_UMPSUB: 'DELETE_UMPSUB',

  MODIFY_LINEUP_ADJUST: 'MODIFY_LINEUP_ADJUST',
  ADD_LINEUP_ADJUST: 'ADD_LINEUP_ADJUST',
  EDIT_LINEUP_ADJUST: 'EDIT_LINEUP_ADJUST',
  DELETE_LINEUP_ADJUST: 'DELETE_LINEUP_ADJUST',

  ADD_PLAY: 'ADD_PLAY',
  ADD_NON_PITCH_EVENT: 'ADD_NON_PITCH_EVENT',

  ADD_MOUND_VISIT: 'ADD_MOUND_VISIT',
  EDIT_MOUND_VISIT: 'EDIT_MOUND_VISIT',
  MODIFY_MOUND_VISIT: 'MODIFY_MOUND_VISIT',

  ADD_BATTER_TIMEOUT: 'ADD_BATTER_TIMEOUT',
  MODIFY_BATTER_TIMEOUT: 'MODIFY_BATTER_TIMEOUT',

  EDIT_UNIFORMS: 'EDIT_UNIFORMS',
}

export const StatusTypes = {
  TRACKING: 'TRACKING',
  BOSS: 'BOSS',
  STRINGER: 'STRINGER',
  API: 'API',
  SB: 'SB',
  REPLAY: 'REPLAY',
}

export const THRESHOLDS = {
  BOSS_CONNECTION_FAILURE: 120,
  BOSS_CONNECTION_WARNING: 60,

  STRINGER_CONNECTION_FAILURE: 120,
  STRINGER_CONNECTION_WARNING: 60,

  TM_CONNECTION_FAILURE: 120,
  TM_CONNECTION_WARNING: 60,

  CH_CONNECTION_FAILURE: 120,
  CH_CONNECTION_WARNING: 60,

  SB_CONNECTION_FAILURE: 120,
  SB_CONNECTION_WARNING: 60,

  GAME_START_WARNING: 120,
  GAME_START_FAILURE: 60,
}

export const ROLES = {
  EDITOR: 'EDITOR',
  POST_GAME: 'POST_GAME',
  STARTERS: 'STARTERS',
  SUBSTITUTIONS: 'SUBSTITUTIONS',
  PRE_GAME: 'PRE_GAME',
  OPERATOR: 'OPERATOR',
  DEBUGGER: 'DEBUGGER',
}

export const BTS_GAME_MODE = {
  UNDEFINED: 'Undefined',
  BATTING_PRACTICE: 'Batting Practice',
  WARM_UP: 'Warm up',
  LIVE: 'Live',
}

export const TRACKMAN_POSITIONS = [
  {
    value: 0,
    label: 'Unknown',
  },
  {
    value: 1,
    label: 'Pitcher',
  },
  {
    value: 2,
    label: 'Catcher',
  },
  {
    value: 3,
    label: 'First Base',
  },
  {
    value: 4,
    label: 'Second Base',
  },
  {
    value: 5,
    label: 'Third Base',
  },
  {
    value: 6,
    label: 'Shortstop',
  },
  {
    value: 7,
    label: 'Left Fielder',
  },
  {
    value: 8,
    label: 'Center Fielder',
  },
  {
    value: 9,
    label: 'Right Fielder',
  },
  {
    value: 10,
    label: 'Batter',
  },
  {
    value: 11,
    label: 'Runner on 1st',
  },
  {
    value: 12,
    label: 'Runner on 2nd',
  },
  {
    value: 13,
    label: 'Runner on 3rd',
  },
  {
    value: 14,
    label: 'Home Plate Umpire',
  },
  {
    value: 15,
    label: 'First Base Umpire',
  },
  {
    value: 16,
    label: 'Second Base Umpire',
  },
  {
    value: 17,
    label: 'Third Base Umpire',
  },
  {
    value: 18,
    label: 'First Base Coach',
  },
  {
    value: 19,
    label: 'Third Base Coach',
  },
  {
    value: 20,
    label: 'Left Foul Line Umpire',
  },
  {
    value: 21,
    label: 'Right Foul Line Umpire',
  },
  {
    value: 22,
    label: 'Manager',
  },
]

export const PlayTypes = {
  SINGLE: 'single',
  DOUBLE: 'double',
  TRIPLE: 'triple',
  HOME_RUN: 'home_run',
  ERROR: 'error',
  ERROR_BASERUNNING: 'error_baserunning',
  FIELDERS_CHOICE: 'fielders_choice',
  STRIKEOUT: 'strikeout',
  WALK: 'walk',
  INTENTIONAL_WALK: 'intentional_walk',
  HIT_BY_PITCH: 'hit_by_pitch',
  CATCHERS_INTERFERENCE: 'catchers_interference',
  OS_PENDING: 'os_pending_play',
  OS_PENDING_BASERUNNING: 'os_pending_prior',
  BATTER_OUT: 'batter_out',
  DOUBLE_PLAY: 'double_play',
  TRIPLE_PLAY: 'triple_play',
  BALK: 'balk',
  FORCED_BALK: 'forced_balk',
  STOLEN_BASE: 'stolen_base',
  CAUGHT_STEALING: 'caught_stealing',
  PICKOFF: 'pickoff',
  PICKOFF_ERROR: 'pickoff_error',
  PICKOFF_CAUGHT_STEALING: 'pickoff_caught_stealing',
  DEFENSIVE_INDIFFERENCE: 'defensive_indifference',
  WILD_PITCH: 'wild_pitch',
  PASSED_BALL: 'passed_ball',
  OTHER_ADVANCE: 'other_advance',
}

export const MovementReasons = {
  ADVANCED_ON_PLAY: '',
  ADVANCED_ON_THROW: 'TH',
  OUT_ON_THROW: 'OTH',
  FORCED_OUT: 'FO',
  ERROR: 'E',
  APPEAL: 'AP',
  FAN_INTERFERENCE: 'fan_interference',
  BATTER_INTERFERENCE: 'batter_interference',
  RUNNER_INTERFERENCE: 'runner_interference',
  HIT_BY_BATTED_BALL: 'BR',
  OUT_STRETCHING: 'out_stretching',
  DOUBLED_OFF_BAG: 'doubled_off',
  OS_PENDING_BASERUNNING: 'os_pending_prior',
  STOLEN_BASE: 'stolen_base',
  CAUGHT_STEALING: 'caught_stealing',
  PICKOFF: 'pickoff',
  PICKOFF_ERROR: 'pickoff_error',
  PICKOFF_CAUGHT_STEALING: 'pickoff_caught_stealing',
  DEFENSIVE_INDIFFERENCE: 'defensive_indifference',
  WILD_PITCH: 'wild_pitch',
  PASSED_BALL: 'passed_ball',
  OTHER_ADVANCE: 'other_advance',
}

export const BaseRunningReasonOptions = [
  {
    value: MovementReasons.ADVANCED_ON_THROW,
    label: 'Advanced On Throw',
  },
  {
    value: MovementReasons.ADVANCED_ON_PLAY,
    label: 'Advanced On Play',
  },
  {
    value: MovementReasons.ADVANCED_ON_PLAY,
    label: 'Out On Play',
  },
  {
    value: MovementReasons.OUT_ON_THROW,
    label: 'Out On Throw',
  },
  {
    value: MovementReasons.FORCED_OUT,
    label: 'Forced Out',
  },
  {
    value: MovementReasons.ERROR,
    label: 'Error',
  },
  {
    value: MovementReasons.APPEAL,
    label: 'Out On Appeal',
  },
  {
    value: MovementReasons.HIT_BY_BATTED_BALL,
    label: 'Hit By Batted Ball',
  },
  {
    value: MovementReasons.OUT_STRETCHING,
    label: 'Out Stretching',
  },
  {
    value: MovementReasons.DOUBLED_OFF_BAG,
    label: 'Doubled Off Bag',
  },
  {
    value: MovementReasons.OS_PENDING_BASERUNNING,
    label: 'Pending Decision',
  },
  {
    value: MovementReasons.STOLEN_BASE,
    label: 'Stolen Base',
  },
  {
    value: MovementReasons.DEFENSIVE_INDIFFERENCE,
    label: 'Defensive Indifference',
  },
  {
    value: MovementReasons.CAUGHT_STEALING,
    label: 'Caught Stealing',
  },
  {
    value: MovementReasons.WILD_PITCH,
    label: 'Wild Pitch',
  },
  {
    value: MovementReasons.PASSED_BALL,
    label: 'Passed Ball',
  },
  {
    value: MovementReasons.PICKOFF,
    label: 'Pickoff',
  },
  {
    value: MovementReasons.PICKOFF_ERROR,
    label: 'Pickoff Error',
  },
  {
    value: MovementReasons.PICKOFF_CAUGHT_STEALING,
    label: 'Pickoff Caught Stealing',
  },
  {
    value: MovementReasons.OTHER_ADVANCE,
    label: 'Other Advance',
  },
  {
    value: MovementReasons.RUNNER_INTERFERENCE,
    label: 'Runner Interference',
  },
  {
    value: MovementReasons.FAN_INTERFERENCE,
    label: 'Fan Interference',
  },
  {
    value: MovementReasons.BATTER_INTERFERENCE,
    label: 'Batter Interference',
  },
]

export const PlayTypeOptions = [
  {
    value: PlayTypes.BATTER_OUT,
    label: 'Batter Out',
  },
  {
    value: PlayTypes.STOLEN_BASE,
    label: 'Stolen Base',
  },
  {
    value: PlayTypes.CAUGHT_STEALING,
    label: 'Caught Stealing',
  },
  {
    value: PlayTypes.DEFENSIVE_INDIFFERENCE,
    label: 'Defensive Indifference',
  },
  {
    value: PlayTypes.WILD_PITCH,
    label: 'Wild Pitch',
  },
  {
    value: PlayTypes.PASSED_BALL,
    label: 'Passed Ball',
  },
  {
    value: PlayTypes.ERROR,
    label: 'Error',
  },
  {
    value: PlayTypes.ERROR_BASERUNNING,
    label: 'Error',
  },
  {
    value: PlayTypes.SINGLE,
    label: 'Single',
  },
  {
    value: PlayTypes.DOUBLE,
    label: 'Double',
  },
  {
    value: PlayTypes.TRIPLE,
    label: 'Triple',
  },
  {
    value: PlayTypes.HOME_RUN,
    label: 'Home Run',
  },
  {
    value: PlayTypes.STRIKEOUT,
    label: 'Strikeout',
  },
  {
    value: PlayTypes.WALK,
    label: 'Walk',
  },
  {
    value: PlayTypes.INTENTIONAL_WALK,
    label: 'Intentional Walk',
  },
  {
    value: PlayTypes.HIT_BY_PITCH,
    label: 'Hit By Pitch',
  },
  {
    value: PlayTypes.FIELDERS_CHOICE,
    label: "Fielder's Choice",
  },
  {
    value: PlayTypes.DOUBLE_PLAY,
    label: 'Double Play',
  },
  {
    value: PlayTypes.TRIPLE_PLAY,
    label: 'Triple Play',
  },
  {
    value: PlayTypes.CATCHERS_INTERFERENCE,
    label: "Catcher's Interference",
  },
  {
    value: PlayTypes.BALK,
    label: 'Balk',
  },
  {
    value: PlayTypes.FORCED_BALK,
    label: 'Forced Balk',
  },
  {
    value: PlayTypes.PICKOFF,
    label: 'Pickoff',
  },
  {
    value: PlayTypes.PICKOFF_ERROR,
    label: 'Pickoff Error',
  },
  {
    value: PlayTypes.PICKOFF_CAUGHT_STEALING,
    label: 'Pickoff Caught Stealing',
  },
  {
    value: PlayTypes.OS_PENDING,
    label: 'Official Scorer Pending',
  },
  {
    value: PlayTypes.OS_PENDING_BASERUNNING,
    label: 'Official Scorer Pending',
  },
  {
    value: PlayTypes.OTHER_ADVANCE,
    label: 'Other Advance',
  },
]

function isBaseRunningPlayType(playType) {
  switch (playType.value) {
    case PlayTypes.STOLEN_BASE:
    case PlayTypes.CAUGHT_STEALING:
    case PlayTypes.DEFENSIVE_INDIFFERENCE:
    case PlayTypes.OTHER_ADVANCE:
    case PlayTypes.WILD_PITCH:
    case PlayTypes.PASSED_BALL:
    case PlayTypes.BALK:
    case PlayTypes.FORCED_BALK:
    case PlayTypes.PICKOFF_CAUGHT_STEALING:
    case PlayTypes.PICKOFF:
    case PlayTypes.PICKOFF_ERROR:
    case PlayTypes.OS_PENDING_BASERUNNING:
    case PlayTypes.ERROR_BASERUNNING:
      return true
  }
  return false
}

export const BaseRunningPlayTypeOptions = PlayTypeOptions.filter((playType) => {
  return isBaseRunningPlayType(playType)
})

export const BatterResultPlayTypeOptions = PlayTypeOptions.filter(
  (playType) => {
    return !isBaseRunningPlayType(playType)
  },
)

export const UNIFORM_ASSET_TYPES = {
  JERSEY: 1,
  PANTS: 2,
  CAP: 3,
  BADGE: 4,
}
